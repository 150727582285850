.image {
  width: 400px;
  height: 400px;
  position: relative;
  top: -50px;
}

.hero-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-content {
  color: white;
  display: flex;
}

.hero-text {
  margin-right: 35px;
  display: flex;
  flex-direction: column;
}

.name {
  font-size: 60px;
  font-weight: 600;
  margin: 0;
}

.title {
  font-size: 40px;
  font-weight: 600;
}

.learn-more {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
}

.arrow {
  width: 30px;
  height: 30px;
}

.learn-more a {
  color: white;
  transition: transform 0.3s ease;
}

.learn-more a:visited {
  color: white;
}

.learn-more a:hover {
  transform: scale(1.1);
}

@media (max-width: 1024px) {
  .learn-more {
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .hero-content {
    flex-direction: column;
    align-items: center;
  }

  .hero-container {
    align-items: normal;
    justify-content: flex-start;
  }

  .image {
    width: 250px;
    height: 250px;
    margin-top: 20px;
    position: relative;
    top: 0;
  }

  .hero-text {
    margin-top: 20px;
    margin-right: 0;
    text-align: center;
    padding: 5px;
  }

  .name {
    font-size: 40px;
  }

  .title {
    font-size: 30px;
  }
}
