#about {
  color: white;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-text {
  text-align: left;
  width: 750px;
  font-size: 18px;
}

h1 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icons {
  width: 50px;
  height: 50px;
}

.icons-container {
  display: flex;
  gap: 50px;
  margin-top: 20px;
}

.download-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 50px;
  background-color: #1a1a1a;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #555;
}

@media (max-width: 768px) {
  #about {
    height: auto;
  }

  .about-text {
    width: 90%;
    font-size: 16px;
    margin: 0 auto;
  }

  .icons {
    width: 40px;
    height: 40px;
  }

  .icons-container {
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .download-button {
    white-space: nowrap;
  }

  .about-content {
    margin-bottom: 150px;
  }
}
