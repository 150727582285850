.footer-container {
  width: 100%;
  background-color: #1a1a1a;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 0;
}

.footer-text {
  color: white;
  font-size: 12px;
  text-align: center;
  margin: 0;
}
