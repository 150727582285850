.header {
  display: flex;
  justify-content: space-between;
  background-position: 0%;
  background-color: #1a1a1a;
  height: 75px;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1000;
}

.header-nav {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-right: 35px;
}

.nav-link {
  color: white;
  text-decoration: none;
  padding: 5px 10px;
  transition: background-color 0.3s ease;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.logo {
  width: 150px;
  height: 150px;
  position: relative;
  top: -40px;
  margin-left: 35px;
}

.header-content {
  display: flex;
  align-items: center;
}

.nav-mobile {
  display: none;
}

@media (max-width: 768px) {
  .header-nav {
    display: none;
  }

  .nav-mobile {
    display: block;
  }

  .logo {
    width: 100px;
    height: 100px;
    top: -15px;
    margin: 0;
  }

  .menu {
    color: white;
    width: 30px;
    height: 30px;
    margin-right: 15px;
    margin-top: 5px;
  }

  .menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .header-nav.mobile {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    width: 70%;
    height: 100vh;
    background-color: #1a1a1a;
    z-index: 1000;
    padding-top: 20px;
    align-items: center;
    transition: transform 0.3s ease;
    transform: translateX(0);
    margin: 0;
  }

  .header-nav:not(.mobile) {
    transform: translateX(100%);
  }
}
